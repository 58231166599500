import * as React from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import ReactJson from "react-json-view";
import Select from "react-select";
import ReactLoading from 'react-loading';

// const {encode, decode} = require('gpt-3-encoder')


export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.askChatGPT = this.askChatGPT.bind(this);
        this.performGet = this.performGet.bind(this);
        this.getPageContent = this.getPageContent.bind(this);
        this.getPageContentEncode = this.getPageContentEncode.bind(this);
        // gpt-4, gpt-4-0314, gpt-4-32k, gpt-4-32k-0314, gpt-3.5-turbo, gpt-3.5-turbo-0301	
        // /v1/completions	text-davinci-003, text-davinci-002, text-curie-001, text-babbage-001, text-ada-001, davinci, curie, babbage, ada	
        // /v1/edits text-davinci-edit-001	text-davinci-edit-001, code-davinci-edit-001	
        // /v1/audio/transcriptions	whisper-1	
        // /v1/audio/translations	whisper-1	
        // /v1/fine-tunes	davinci, curie, babbage, ada	
        // /v1/embeddings	text-embedding-ada-002, text-search-ada-doc-001	
        // /v1/moderations	text-moderation-stable, text-moderation-latest	

        const endpoints = [{
            endpoint: "/v1/chat/completions",
            engines: ["gpt-4", "gpt-4-0314", "gpt-4-32k", "gpt-4-32k-0314", "gpt-3.5-turbo", "gpt-3.5-turbo-0301"
            ]
        }, {
            endpoint: "/v1/completions",
            engines: ["text-davinci-003", "text-davinci-002", "text-curie-001", "text-babbage-001", "text-ada-001", "davinci", "curie", "babbage", "ada"]
        }, {
            endpoint: "/v1/edits",
            engines: ["text-davinci-edit-001", "text-davinci-edit-001", "code-davinci-edit-001"]
            // }, {
            //     endpoint: "/v1/audio/transcriptions",
            //     engines: ["whisper-1"]
            // }, {
            //     endpoint: "/v1/audio/translations",
            //     engines: ["whisper-1"]
            // }, {
            //     endpoint: "/v1/fine-tunes",
            //     engines: ["davinci", "curie", "babbage", "ada"]
            // }, {
            //     endpoint: "/v1/embeddings",
            //     engines: ["text-embedding-ada-002", "text-search-ada-doc-001"]
            // }, {
            //     endpoint: "/v1/moderations",
            //     engines: ["text-moderation-stable", "text-moderation-latest"]
        }];

        const engines = [
            {
                title: "Mô hình GPT-4 (25 messages every 3 hours) 8,192 tokens End point: /v1/chat/completions",
                value: "gpt-4",
                label: "gpt-4",
            }, {
                title: "With 4x the context length = 32,768 tokens End point: /v1/chat/completions",
                value: "gpt-4-32k",
                label: "gpt-4-32k",
            }, {
                title: "Fastest Model-End point: /v1/chat/completions",
                value: "gpt-3.5-turbo",
                label: "gpt-3.5-turbo",
            }, {
                title: "Update 6 năm 2021-End point: /v1/completions",
                value: "text-davinci-003",
                label: "text-davinci-003",
            }, {
                title:
                    "6 năm 2021 -End point: /v1/completions",
                value: "text-davinci-002",
                label: "text-davinci-002",
            }, {
                title: "text-curie-001",
                value: "text-curie-001",
                label: "text-curie-001",
            }, {
                title: "text-babbage-001",
                value: "text-babbage-001",
                label: "text-babbage-001",
            }, {
                title: "text-ada-001",
                value: "text-ada-001",
                label: "text-ada-001",
            }, {
                title: "davinci",
                value: "davinci",
                label: "davinci",
            }, {
                title: "curie",
                value: "curie",
                label: "curie",
            }, {
                title: "babbage",
                value: "babbage",
                label: "babbage",
            }, {
                title: "ada",
                value: "ada",
                label: "ada",
            }, {
                title: "Được tối ưu hóa cho các tác vụ Code -End point: /v1/completions",
                value: "code-davinci-002",
                label: "code-davinci-002",
            },
        ];
        const engine = engines[3];

        this.state = {
            page_content: "Page content will be here",
            page_content_encoded: '',
            chatgpt_result: {
                choices: [
                    {
                        text: "ChatGPT result",
                    },
                ],
            },
            params: {
                url: "",
                question: "",
            },
            engines: engines,
            engine: engine,
            asking_gpt: false,
            get_content: false,
            endpoints: endpoints
        };
    }

    async performGet($url) {
        const params = new URLSearchParams({
            url: $url,
        });
        const str = params.toString();


        // var url = `https://cautious-angry-thought.glitch.me/content/?${str}`;
        var url = `https://raspado-q1o4.onrender.com/scapy?${str}`;
        let getResponse = await axios
            .get(url)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return { code: 404, data: error.toString() };
            });
        if (getResponse.status === 200) {
            return getResponse.data;
        }
        return { code: 400, data: "error" };
    }
    getPageContentEncode() {
    }
    async getPageContent() {
        var self = this;
        const formCheck = document.getElementById("formCheck");
        const form_data = new FormData(formCheck);
        var params = {};
        form_data.forEach(function (value, key) {
            params[key] = value;
        });

        if (params.url === "") {
            toast("URL missing!");
            return;
        }
        console.log(`Get content of ${params.url}`);
        self.setState({ get_content: true });
        var getResponse = await self.performGet(params.url);
        var page_result = getResponse.data;
        var page_content = [];
        if (page_result.title && page_result.title.length > 0) {
            page_content.push(getResponse.data.title);
        }
        if (page_result.description && page_result.description.length > 0) {
            page_content.push(getResponse.data.description);
        }
        if (page_result.keywords && page_result.keywords.length > 0) {
            page_content.push(getResponse.data.keywords);
        }

        if (page_result.headings && page_result.headings.length > 0) {
            page_result.headings.forEach(element => {
                page_content.push(element);
            });
        }
        if (page_result.list && page_result.list.length > 0) {
            page_result.list.forEach(element => {
                page_content.push(element);
            });
        }
        if (page_result.paragraphs && page_result.paragraphs.length > 0) {
            page_result.paragraphs.forEach(element => {
                page_content.push(element);
            });
        }
        page_content = page_content.join('\n');
        var removeList = params.common_ignore.split(/[\n;]/);
        page_content = removeList.reduce((str, item) => {
            return str.replace(new RegExp(item, "g"), "");
        }, page_content);
        self.setState({ params: params, page_content: page_content, get_content: false });
    }
    async askChatGPT() {
        var self = this;
        var { page_content, endpoints } = self.state;
        const formCheck = document.getElementById("formCheck");
        const form_data = new FormData(formCheck);
        var params = {};
        form_data.forEach(function (value, key) {
            params[key] = value;
        });

        if (params.api === "" || params.url === "" || params.question === "") {
            toast("API Key or URL or Question missing!");
            return;
        }
        self.setState({ asking_gpt: true });
        if (!page_content || page_content.length === 0) {
            var getResponse = await self.performGet(params.url);
            var page_result = getResponse.data;
            page_content = [];
            if (page_result.title && page_result.title.length > 0) {
                page_content.push(getResponse.data.title);
            }
            if (page_result.description && page_result.description.length > 0) {
                page_content.push(getResponse.data.description);
            }
            if (page_result.keywords && page_result.keywords.length > 0) {
                page_content.push(getResponse.data.keywords);
            }

            if (page_result.headings && page_result.headings.length > 0) {
                page_result.headings.forEach(element => {
                    page_content.push(element);
                });
            }
            if (page_result.list && page_result.list.length > 0) {
                page_result.list.forEach(element => {
                    page_content.push(element);
                });
            }
            if (page_result.paragraphs && page_result.paragraphs.length > 0) {
                page_result.paragraphs.forEach(element => {
                    page_content.push(element);
                });
            }
            page_content = page_content.join('\n');
            var removeList = params.common_ignore.split(/[\n;]/);
            page_content = removeList.reduce((str, item) => {
                return str.replace(new RegExp(item, "g"), "");
            }, page_content);
        }
        var prompt = `${params.question} ${page_content}`;
        var engine = params.engine;
        var max_tokens = parseInt(params.max_tokens);
        var temperature = parseFloat(params.temperature);
        var top_p = parseInt(params.top_p);
        var frequency_penalty = parseFloat(params.frequency_penalty);
        var limit = 4000 - max_tokens;
        // var limit = 4000;
        prompt = prompt.slice(0, limit);
        var data = JSON.stringify({
            model: engine,
            prompt: prompt,
            max_tokens: max_tokens,
            top_p: top_p,
            frequency_penalty: frequency_penalty,
            temperature: temperature,
        });
         var url = "https://api.openai.com";
        endpoints.forEach(endpoint => {
            if (endpoint.engines.includes(engine)) {
                url += endpoint.endpoint;
            }
        });
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: `Bearer ${params.api}`,
                "Content-Type": "application/json",
            },
            data: data,
        };
        var chatgpt_result = await axios(config)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error.toString();
            });
        self.setState({
            params: params,
            page_content: prompt,
            chatgpt_result: chatgpt_result,
            asking_gpt: false
        });
    }
    render() {
        var self = this;
        var { engines, engine, page_content, page_content_encoded, chatgpt_result, asking_gpt, get_content } = self.state;

        var chatgpt_result_text = '';
        if (chatgpt_result && chatgpt_result.choices && chatgpt_result.choices.length > 0) {
            chatgpt_result_text = chatgpt_result.choices[0].text;
        }
        chatgpt_result_text = chatgpt_result_text.replace(/[\n\t ]+/g, ' ');
        /*{page_content.length > 0?<div className="form-group text-right d-flex">
                      <button className="btn btn-info mt-3 ml-3" onClick={self.getPageContentEncode}>
                        Encode
                                  </button>
                    </div>:''}
                    <div className="form-group">                  
                      <textarea
                        className="form-control"
                        rows="10"
                        value={page_content_encoded}
                        readOnly
                      ></textarea>
                    </div>
                    */
        return (
            <>
                <Container>
                    <h1>Website Check</h1>
                    <form id="formCheck">
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label className="form-label">
                                        API key
                                        <a href="https://platform.openai.com/docs/api-reference"
                                            target="_blank" rel="noreferrer">(Api-reference</a> | <a href="https://platform.openai.com/docs/models/model-endpoint-compatibility"
                                            target="_blank" rel="noreferrer">Model endpoint</a> | <a href="https://openai.com/pricing"
                                            target="_blank" rel="noreferrer">Pricing)</a>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="api"
                                        placeholder="ChatGPT key get her: https://platform.openai.com/account/api-keys"
                                        defaultValue="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"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" for="engine">
                                        Select engine:
                                        <a
                                            href="https://platform.openai.com/docs/models"
                                            target="_blank" rel="noreferrer" >
                                            (Models-reference)
                                        </a>
                                    </label>
                                    <Select
                                        class="form-control"
                                        name="engine"
                                        options={engines}
                                        value={engine}
                                        onChange={(selected, actionMeta) => {
                                            self.setState({ engine: selected });
                                        }}
                                    ></Select>
                                    <label className="text-success">{engine.title}</label>
                                </div>
                                <div className="form-group">
                                    <label title="Giới hạn trên về số lượng mã thông báo mà API sẽ trả về" className="form-label">
                                        Max tokens
                                        <a
                                            href="https://platform.openai.com/tokenizer"
                                            target="_blank" rel="noreferrer"
                                        > (Check tokenizer)
                                        </a>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="max_tokens"
                                        placeholder="Max tokens"
                                        defaultValue={250}
                                    />
                                </div>
                                <div className="form-group">
                                    <label title="Mặc định là 1: (tương tự 'temperature') Càng nhỏ thì độ chính xác càng cao" className="form-label">top_p</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="top_p"
                                        placeholder="n"
                                        defaultValue={0.5}
                                    />
                                </div>
                                <div className="form-group">
                                    <label title="Mặc định là 0 (-2:2) Các giá trị dương sẽ phạt các mã thông báo mới dựa trên tần suất hiện có của chúng trong văn bản cho đến nay, làm giảm khả năng mô hình lặp lại nguyên văn cùng một dòng." className="form-label">frequency_penalty</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="frequency_penalty"
                                        placeholder="n"
                                        defaultValue={0.5}
                                    />
                                </div>
                                <div className="form-group">
                                    <label title="Mặc định là 1: Nên sử dụng nhiệt độ lấy mẫu nào, trong khoảng từ 0 đến 2. Các giá trị cao hơn như 0,8 sẽ làm cho đầu ra ngẫu nhiên hơn, trong khi các giá trị thấp hơn như 0,2 sẽ làm cho đầu ra tập trung và xác định hơn." className="form-label">Temperature</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="temperature"
                                        placeholder="ChatGPT key"
                                        defaultValue={0.5}
                                    />
                                </div>

                                <hr />

                                <div className="form-group">
                                    <label className="form-label">Question</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="question"
                                        defaultValue="Nghành nghề 2022-NAICS-Codes là gì, chỉ lấy ký tự số, 10 mã Six digit phù hợp nhất: "
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Common ignore keywords</label>
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        name="common_ignore"
                                        defaultValue="Liên hệ"
                                    ></textarea>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group ">
                                    <label className="form-label">URL</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="url"
                                        placeholder="URL"
                                    />
                                </div>
                                <div className="form-group text-right d-flex">
                                    <Button className="btn mt-3" onClick={self.getPageContent}>
                                        Get Content
                                    </Button>
                                    {get_content ?
                                        <div className='ml-3'>
                                            <ReactLoading type='spin' color='blue' height={37} width={37} />
                                        </div> : ''}
                                </div>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows="24"
                                        value={page_content}
                                        readOnly
                                    ></textarea>
                                </div>
                                {page_content.length > 0 ? <div className="form-group text-right d-flex">
                                    <button className="btn btn-info mt-3 ml-3" onClick={self.getPageContentEncode}>
                                        Encode
                                    </button>
                                </div> : ''}
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows="10"
                                        value={page_content_encoded}
                                    ></textarea>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <div className="mb-3 d-flex ">
                                    <Button
                                        className="btn btn-success ml-3"
                                        onClick={self.askChatGPT}
                                    >
                                        Submit
                                    </Button>
                                    {asking_gpt ?
                                        <div className='ml-3'>
                                            <ReactLoading type='spin' color='blue' height={37} width={37} />
                                        </div> : ''}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <div className="form-group">
                                    <label className="form-label">ChatGPT Result</label>
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        value={chatgpt_result_text}
                                        readOnly
                                    ></textarea>
                                    {/* <ReactJson src={chatgpt_result} /> */}
                                </div>
                            </Col>
                        </Row>
                    </form>

                    <ToastContainer />
                </Container>
            </>
        );
    }
}
